import * as i0 from '@angular/core';
import { Injectable, inject, isDevMode, NgModule } from '@angular/core';
import * as i2 from '@spartacus/core';
import { Config, LoggerService, provideDefaultConfig, provideDefaultConfigFactory } from '@spartacus/core';
import { HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { tap } from 'rxjs/operators';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class PersonalizationConfig {
  static {
    this.ɵfac = function PersonalizationConfig_Factory(t) {
      return new (t || PersonalizationConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PersonalizationConfig,
      factory: function PersonalizationConfig_Factory(t) {
        let r = null;
        if (t) {
          r = new (t || PersonalizationConfig)();
        } else {
          r = i0.ɵɵinject(Config);
        }
        return r;
      },
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PersonalizationConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useExisting: Config
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const PERSONALIZATION_FEATURE = 'personalization';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultPersonalizationConfig = {
  personalization: {
    enabled: false,
    httpHeaderName: {
      id: 'Occ-Personalization-Id',
      timestamp: 'Occ-Personalization-Time'
    },
    context: {
      slotPosition: 'PlaceholderContentSlot',
      componentId: 'PersonalizationScriptComponent'
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class OccPersonalizationIdInterceptor {
  constructor(config, occEndpoints, winRef) {
    this.config = config;
    this.occEndpoints = occEndpoints;
    this.winRef = winRef;
    this.enabled = false;
    this.PERSONALIZATION_ID_KEY = 'personalization-id';
    this.logger = inject(LoggerService);
    if (this.winRef.isBrowser()) {
      this.enabled = this.winRef.localStorage && this.config.personalization?.enabled || false;
      if (this.enabled) {
        if (!this.config.personalization?.httpHeaderName && isDevMode()) {
          this.logger.warn(`There is no httpHeaderName configured in Personalization`);
        }
        this.requestHeader = this.config.personalization?.httpHeaderName?.id.toLowerCase();
        this.personalizationId = this.winRef.localStorage?.getItem(this.PERSONALIZATION_ID_KEY);
      } else if (this.winRef.localStorage?.getItem(this.PERSONALIZATION_ID_KEY)) {
        this.winRef.localStorage.removeItem(this.PERSONALIZATION_ID_KEY);
      }
    }
  }
  intercept(request, next) {
    if (!this.enabled) {
      return next.handle(request);
    }
    if (this.requestHeader && this.personalizationId && request.url.includes(this.occEndpoints.getBaseUrl())) {
      request = request.clone({
        setHeaders: {
          [this.requestHeader]: this.personalizationId
        }
      });
    }
    return next.handle(request).pipe(tap(event => {
      if (event instanceof HttpResponse && this.requestHeader && event.headers.keys().includes(this.requestHeader)) {
        const receivedId = event.headers.get(this.requestHeader);
        if (this.personalizationId !== receivedId) {
          this.personalizationId = receivedId;
          if (this.personalizationId) {
            this.winRef.localStorage?.setItem(this.PERSONALIZATION_ID_KEY, this.personalizationId);
          }
        }
      }
    }));
  }
  static {
    this.ɵfac = function OccPersonalizationIdInterceptor_Factory(t) {
      return new (t || OccPersonalizationIdInterceptor)(i0.ɵɵinject(PersonalizationConfig), i0.ɵɵinject(i2.OccEndpointsService), i0.ɵɵinject(i2.WindowRef));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OccPersonalizationIdInterceptor,
      factory: OccPersonalizationIdInterceptor.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OccPersonalizationIdInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: PersonalizationConfig
  }, {
    type: i2.OccEndpointsService
  }, {
    type: i2.WindowRef
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class OccPersonalizationTimeInterceptor {
  constructor(config, occEndpoints, winRef) {
    this.config = config;
    this.occEndpoints = occEndpoints;
    this.winRef = winRef;
    this.enabled = false;
    this.PERSONALIZATION_TIME_KEY = 'personalization-time';
    this.logger = inject(LoggerService);
    if (this.winRef.isBrowser()) {
      this.enabled = this.winRef.localStorage && this.config.personalization?.enabled || false;
      if (this.enabled) {
        if (!this.config.personalization?.httpHeaderName && isDevMode()) {
          this.logger.warn(`There is no httpHeaderName configured in Personalization`);
        }
        this.requestHeader = this.config.personalization?.httpHeaderName?.timestamp.toLowerCase();
        this.timestamp = this.winRef.localStorage?.getItem(this.PERSONALIZATION_TIME_KEY);
      } else if (this.winRef.localStorage?.getItem(this.PERSONALIZATION_TIME_KEY)) {
        this.winRef.localStorage.removeItem(this.PERSONALIZATION_TIME_KEY);
      }
    }
  }
  intercept(request, next) {
    if (!this.enabled) {
      return next.handle(request);
    }
    if (this.requestHeader && this.timestamp && request.url.includes(this.occEndpoints.getBaseUrl())) {
      request = request.clone({
        setHeaders: {
          [this.requestHeader]: this.timestamp
        }
      });
    }
    return next.handle(request).pipe(tap(event => {
      if (event instanceof HttpResponse && this.requestHeader && event.headers.keys().includes(this.requestHeader)) {
        const receivedTimestamp = event.headers.get(this.requestHeader);
        if (this.timestamp !== receivedTimestamp) {
          this.timestamp = receivedTimestamp;
          if (this.timestamp) {
            this.winRef.localStorage?.setItem(this.PERSONALIZATION_TIME_KEY, this.timestamp);
          }
        }
      }
    }));
  }
  static {
    this.ɵfac = function OccPersonalizationTimeInterceptor_Factory(t) {
      return new (t || OccPersonalizationTimeInterceptor)(i0.ɵɵinject(PersonalizationConfig), i0.ɵɵinject(i2.OccEndpointsService), i0.ɵɵinject(i2.WindowRef));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OccPersonalizationTimeInterceptor,
      factory: OccPersonalizationTimeInterceptor.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OccPersonalizationTimeInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: PersonalizationConfig
  }, {
    type: i2.OccEndpointsService
  }, {
    type: i2.WindowRef
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const interceptors = [{
  provide: HTTP_INTERCEPTORS,
  useExisting: OccPersonalizationIdInterceptor,
  multi: true
}, {
  provide: HTTP_INTERCEPTORS,
  useExisting: OccPersonalizationTimeInterceptor,
  multi: true
}];

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
// TODO: Inline this factory when we start releasing Ivy compiled libraries
function defaultPersonalizationComponentsConfig() {
  const config = {
    featureModules: {
      [PERSONALIZATION_FEATURE]: {
        cmsComponents: ['PersonalizationScriptComponent']
      }
    }
  };
  return config;
}
class PersonalizationRootModule {
  static {
    this.ɵfac = function PersonalizationRootModule_Factory(t) {
      return new (t || PersonalizationRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PersonalizationRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [...interceptors, provideDefaultConfig(defaultPersonalizationConfig), provideDefaultConfigFactory(defaultPersonalizationComponentsConfig)]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PersonalizationRootModule, [{
    type: NgModule,
    args: [{
      providers: [...interceptors, provideDefaultConfig(defaultPersonalizationConfig), provideDefaultConfigFactory(defaultPersonalizationComponentsConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { OccPersonalizationIdInterceptor, OccPersonalizationTimeInterceptor, PERSONALIZATION_FEATURE, PersonalizationConfig, PersonalizationRootModule, defaultPersonalizationComponentsConfig };
